import React from 'react'
import 'twin.macro'
import { HOTLINE_CSKH, ZALO_CHAT, FB_CHAT } from '../../utils/constants'
import RingCircle from '../Base/ChatIcons/RingCircle'

const ButtonRightButtons = () => {
  return (
    <div tw="fixed z-50 bottom-10 md:bottom-20 right-0 flex flex-col">
      <RingCircle href={FB_CHAT} type="facebook-chat" />
      <RingCircle href={ZALO_CHAT} type="zalo-chat" />
      <RingCircle href={`tel: ${HOTLINE_CSKH}`} type="phone-call" />
    </div>
  )
}

export default ButtonRightButtons

import React from 'react'
import tw, { styled } from 'twin.macro'
import { Segment } from 'semantic-ui-react'

const SectionContainer = styled.div`
  padding: ${(props) => (props.padding ? `${props.padding}` : '0 0')};
  padding-top: ${(props) => (props.paddingTop ? `${props.paddingTop}` : '0 0')};
  background: ${(props) => (props.background ? `${props.background}` : `#FFF`)};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
`
const SectionWrapper = styled.div`
  position: relative;
  object-fit: cover;
  object-position: center;
`
const IndexSectionWrapper = styled(SectionWrapper)`
  padding: ${(props) => (props.padding ? `${props.padding}` : '0')};
  background: ${(props) => (props.background ? `${props.background}` : ``)};
`

const StaticImgSectionWrapper = ({ children, id }) => {
  return <IndexSectionWrapper id={id}>{children}</IndexSectionWrapper>
}

const Line = styled.div`
  width: 126px;
  height: 6px;
  background: #61a05c;

  border-radius: 5px;
  ${(props) => (props.center ? `margin: 0 auto;` : ``)}
  margin-top: 10px;
`

const Heading1 = styled.h1`
  font-family: 'Lexend', sans-serif !important;
  font-weight: 700;
  color: #61a05c;
  text-align: center;

  font-size: 30px;
  line-height: 125%;
  @media only screen and (min-width: 1024px) {
    font-size: 48px;
  }
`

const Heading2 = styled.h2`
  font-family: 'Lexend', sans-serif !important;
  color: #61a05c;
  font-weight: 700;
  font-size: 26px;
  line-height: 125%;
  text-align: center;
  @media only screen and (min-width: 1024px) {
    font-size: 44px;
  }
`

const InboxText = styled.p`
  font-family: 'Lexend', sans-serif !important;
  font-weight: 700;
  color: #61a05c;
  text-align: center;
  font-size: 22px;
  line-height: 160%;
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
  text-shadow:
    2px 0 #0f7545,
    -2px 0 #0f7545,
    0 2px #0f7545,
    0 -2px #0f7545,
    2px 2px #0f7545,
    -2px -2px #0f7545,
    2px -2px #0f7545,
    -2px 2px #0f7545;
  @media only screen and (min-width: 1024px) {
    font-size: 36px;
  }
`
const Heading3 = styled.h3`
  font-family: 'Lexend', sans-serif !important;
  color: #61a05c;
  font-weight: 700;
  font-size: 24px;
  line-height: 160%;
  padding: 3px 0;
  padding-left: 20px;
  position: relative;
  text-align: left;
  margin-bottom: 10px;
  margin-top: 10px;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 6px;
    background-color: #61a05c;
    display: block;
    border-radius: 5px;
  }
  @media only screen and (min-width: 1024px) {
    font-size: 36px;
  }
`
const Description = styled.div`
  color: ${(props) => (props.color ? props.color : '#404040')};
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: ${(props) => (props.bold ? 700 : 500)};
  line-height: 150%;
  font-size: 16px;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  width: 90%;
  @media only screen and (min-width: 1024px) {
    font-size: 20px;
    line-height: 160%;
    margin: 15px 0;
    margin-right: ${(props) => (props.marginBigScreenNotAuto ? 0 : 'auto')};
    margin-left: ${(props) => (props.marginBigScreenNotAuto ? 0 : 'auto')};
    width: 85%;
  }
`

const ProductSection = styled(Segment).attrs({
  vertical: true,
})`
  border-bottom: none !important;
`

const BorderBox = tw.div`
  w-32 h-1 bg-savor-cake-primary-green-avocado-100
  rounded-md mb-4 lg:mb-8`

const GreenBorder = tw.div`rounded-xl border-2 border-savor-cake-primary-green-avocado-0 mb-4 mx-6 md:mx-8 lg:mx-0 py-4`

const GreenBorderStyle = tw`border-t-[0.5px] border-savor-cake-primary-green-avocado-0`

export {
  Line,
  Heading1,
  Heading2,
  Heading3,
  ProductSection,
  Description,
  InboxText,
  SectionContainer,
  StaticImgSectionWrapper,
  SectionWrapper,
  BorderBox,
  GreenBorder,
  GreenBorderStyle,
}

import React, { useEffect, useState } from 'react'
import { Button } from 'semantic-ui-react'
import { Link } from 'gatsby'
import tw from 'twin.macro'
import { compareWithEventDay } from '../../utils/eventDays'

const NotFoundText = tw.div`h-44 sm:h-96 flex justify-center items-center flex-col text-base`

export const PageLinks = () => {
  return (
    <div tw="flex flex-row flex-wrap justify-center items-start gap-2 mx-4">
      <Link to="/">
        <Button
          tw="bg-savor-cake-primary-green-avocado-0!
      text-white! font-savor-cake-secondary-quicksand!"
        >
          Xem Bánh sinh nhật
        </Button>
      </Link>
      {compareWithEventDay({
        dayLabel: 'mooncake',
        compareType: 'sameOrBefore',
      }) && (
        <Link to="/banh-trung-thu/">
          <Button
            tw="bg-savor-cake-primary-yellow-banana-0!
        text-savor-cake-secondary-blueberry-0!
        font-savor-cake-secondary-quicksand!"
          >
            Xem Bánh Trung Thu
          </Button>
        </Link>
      )}
      {/* {compareWithEventDay({
        dayLabel: '20t10',
        compareType: 'sameOrBefore',
      }) && (
        <Link to="/2010">
          <Button
            tw="bg-savor-cake-pink-600!
        text-white!
        font-savor-cake-secondary-quicksand!"
          >
            Xem Bánh kem 20/10
          </Button>
        </Link>
      )} */}
      {compareWithEventDay({
        dayLabel: '8t3',
        compareType: 'sameOrBefore',
      }) && (
        <Link to="/83/">
          <Button
            tw="bg-savor-cake-pink-600!
        text-white!
        font-savor-cake-secondary-quicksand!"
          >
            Xem Bánh kem 8/3
          </Button>
        </Link>
      )}
    </div>
  )
}

const NoItemInCart = ({ content }) => {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), 2000)
    return () => clearTimeout(timer)
  }, [])

  return (
    <div tw="max-w-xl mx-2 sm:mx-auto grid place-content-center flex-1 min-h-full h-[30rem]">
      {isLoading ? (
        <NotFoundText>Đang xử lý ...</NotFoundText>
      ) : (
        <>
          <p tw="text-base md:text-lg lg:text-xl text-center">
            {content || 'Không có sản phẩm nào trong giỏ hàng'}
          </p>
          <PageLinks />
        </>
      )}
    </div>
  )
}

export default NoItemInCart

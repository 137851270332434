import { toast } from 'react-toastify'

const toastErrorSettings = {
  position: 'top-right',
  autoClose: 9000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored',
}
const toastSuccessSettings = {
  position: 'top-right',
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
  theme: 'colored',
}
const toastInfoSettings = {
  position: 'top-right',
  autoClose: 9000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored',
}

export const errorToast = (message = '', customSettings = {}) => {
  return toast.error(message, { ...toastErrorSettings, ...customSettings })
}
export const successToast = (message = '', customSettings = {}) => {
  return toast.success(message, { ...toastSuccessSettings, ...customSettings })
}

export const infoToast = (message = '', customSettings = {}) => {
  return toast.info(message, { ...toastInfoSettings, ...customSettings })
}

import React from 'react'
import 'twin.macro'
import { FiPhoneCall } from 'react-icons/fi'
import { FaFacebookMessenger } from 'react-icons/fa'

import './chat-icons.css'

const RingCircle = ({ href, type }) => {
  return (
    <a
      className="wrapper"
      tw="block text-black hover:text-black"
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="ring">
        <div
          className={`coccoc-alo-phone coccoc-alo-green coccoc-alo-show ${type}`}
        >
          {/* <div className="coccoc-alo-ph-circle"></div> */}
          <div className="coccoc-alo-ph-circle-fill"></div>
          <div className="coccoc-alo-ph-img-circle">
            {type === 'phone-call' ? (
              <FlexCenterBox>
                <FiPhoneCall size={19} />
              </FlexCenterBox>
            ) : null}
            {type === 'zalo-chat' ? (
              <FlexCenterBox>
                <p tw="text-white text-center font-semibold">Zalo</p>
              </FlexCenterBox>
            ) : null}
            {type === 'facebook-chat' ? (
              <FlexCenterBox>
                <FaFacebookMessenger size={34} color="#168AFF" />
              </FlexCenterBox>
            ) : null}
          </div>
        </div>
      </div>
    </a>
  )
}

export default RingCircle

const FlexCenterBox = ({ children }) => {
  return (
    <div tw="w-full h-full flex items-center justify-center">{children}</div>
  )
}

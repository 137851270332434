import React, { useMemo } from 'react'
import { map } from 'lodash'
import Layout from '../components/Layouts/cakeDefault'
import TailwindLayout from '../components/Layouts/TailwindLayout'
import tw, { styled } from 'twin.macro'
import { FaCopy } from 'react-icons/fa'

import { PageLinks } from '../components/DatHangPage/NoItemInCart'
import orderSuccessVector from '../assets/svg/order-success.svg'
import { currencyFormatter } from '../utils/utils'
import { HOTLINE_COMPLAINT, NEED_CSKH_CHECK, siteUrl } from '../utils/constants'
import { successToast } from '../utils/toastify'
import {
  GreenBorder,
  GreenBorderStyle,
} from '../components/BanhSinhNhatPage/Base'
import ButtonRightButtons from '../components/Shared/BottomRightButtons'

const getTrackingLink = ({ onlineOrderId, createdTs }) => {
  return `${
    process.env.WEBSITE_URL || siteUrl
  }/orders/tracking/?onlineOrderId=${onlineOrderId}&createdTs=${createdTs}`
}

export default function ThankYouPage({ location }) {
  // get onlineOrderId param from location.search
  const searchParams = new URLSearchParams(location.search)
  const payment = searchParams.get('payment')
  const onlineOrderId = searchParams.get('onlineOrderId')
  const amount = searchParams.get('amount')
  const offOrder = searchParams.get('offOrder')
  const createdTs = searchParams.get('createdTs')
  const mooncakeCount = searchParams.get('mooncakeCount')
  const showQR = useMemo(() => {
    if (NEED_CSKH_CHECK && mooncakeCount > 0) {
      return false
    }
    return payment === 'transfer' && offOrder !== 'true'
  }, [mooncakeCount, payment, offOrder])
  const transferContent = `DH${onlineOrderId}`

  const [fetchingQr, setFetchingQR] = React.useState(false)
  const [qrLink, setQrLink] = React.useState('')
  const [errorQr, setErrorQr] = React.useState('')
  const [transferInfo, setTransferInfo] = React.useState({})

  React.useEffect(() => {
    const fetchQrLink = async () => {
      const res = await fetch(
        `${process.env.GATSBY_API_URL}/get-qr-payment-link`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            transferContent,
            totalMoney: amount,
          }),
        }
      )
      const resJson = await res.json()

      if (resJson && resJson.error) {
        setErrorQr(`Lỗi khi lấy dữ liệu thanh toán: \n${resJson.error}`)
        return
      }
      if (!resJson || !resJson.link) {
        setErrorQr('Lỗi khi lấy dữ liệu thanh toán')
        return
      }
      setQrLink(resJson.link)
      const transferInfo = resJson.transferInfo || {}
      setTransferInfo(transferInfo)
    }
    // start fetching
    setFetchingQR(true)
    fetchQrLink()
      .catch((error) => {
        console.log(error)
        setErrorQr(error.message)
      })
      .finally(() => {
        setFetchingQR(false)
      })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const transferInfoMap = [
    {
      normal: 'Ngân hàng: ',
      strong: transferInfo?.bankName,
      // spanNormal: ' (Chi nhánh Thành Công)',
    },
    {
      normal: 'Chủ tài khoản: ',
      strong: transferInfo?.accountName,
    },
    {
      normal: 'Số tài khoản: ',
      strong: transferInfo?.accountNumber,
    },
    {
      normal: 'Tổng tiền: ',
      strong: currencyFormatter.format(amount),
    },
  ]
  return (
    <TailwindLayout>
      <Layout endPoint={'thank-you'} title={'Savor Cake | Thank You'}>
        <ButtonRightButtons />
        <div tw="flex justify-center items-center mt-8 md:mt-10">
          <OrderSuccess />
        </div>
        <div tw="mx-auto lg:container lg:max-w-screen-lg!">
          {/* Lời cảm ơn */}
          <div
            tw="font-savor-cake-primary-lexend text-2xl sm:text-3xl md:text-4xl lg:text-5xl
                  font-bold text-center leading-tight py-4"
          >
            Cảm ơn bạn đã đặt hàng tại <br />
            <span tw="text-savor-cake-primary-green-avocado-0">
              Savor Cakes!
            </span>
            <br />
            <span
              tw="text-lg sm:text-xl md:text-2xl lg:text-3xl
                    font-bold text-center"
            >
              Mã đơn hàng của bạn là{' '}
              <strong tw="text-savor-cake-primary-green-avocado-0">
                {onlineOrderId}
              </strong>
            </span>
          </div>
          {createdTs && createdTs !== 'undefined' ? (
            <a
              href={getTrackingLink({
                onlineOrderId,
                createdTs,
              })}
              target="_blank"
              rel="noopener noreferrer"
              tw="text-center text-savor-cake-secondary-red-strawberry-0 font-semibold text-lg md:text-xl lg:text-2xl mb-8 block w-full mx-auto"
            >
              Theo dõi đơn hàng này tại đây!
            </a>
          ) : null}
          {/* Payment */}
          {showQR ? (
            <GreenBorder>
              {/* Overview Guiding */}
              <div>
                <TransferPara>
                  Vui lòng thanh toán theo mã QR hoặc thông tin dưới đây.
                </TransferPara>
                <TransferPara>
                  Sau đó <strong>gửi lại</strong> ảnh giao dịch qua Zalo hoặc
                  Messenger giúp Savor nha!
                </TransferPara>
                {/* <TransferPara>
                  <ZaloLink />
                </TransferPara> */}
              </div>
              {/* Payment info */}
              {fetchingQr || errorQr ? (
                <div tw="text-center text-savor-cake-secondary-red-strawberry-0 pb-3 text-base md:text-lg">
                  {fetchingQr ? 'Đang tải mã QR ...' : errorQr}
                </div>
              ) : null}
              {!fetchingQr && !errorQr ? (
                <div tw="grid auto-cols-auto lg:grid-cols-2 pb-4 md:pb-0 lg:mx-12 px-2">
                  <div tw="row-start-1 md:col-start-2 flex flex-col items-center justify-center w-auto pt-2 md:pt-0">
                    {qrLink ? (
                      <img
                        src={qrLink}
                        tw="w-3/5 md:w-4/5 lg:w-2/3"
                        alt="Mã QR Code"
                      />
                    ) : null}
                  </div>
                  <div tw="row-start-2 md:col-start-1 md:row-start-1 my-auto flex flex-col pt-2 md:pt-0">
                    {map(transferInfoMap, (infoText, index) => (
                      <TransferPara
                        key={index}
                        onClick={
                          infoText.normal === 'Số tài khoản: '
                            ? () => {
                                navigator.clipboard.writeText(infoText.strong)
                                successToast('Đã copy số tài khoản', {
                                  autoClose: 500,
                                  hideProgressBar: true,
                                  pauseOnHover: false,
                                })
                              }
                            : undefined
                        }
                        css={
                          infoText.normal === 'Số tài khoản: ' &&
                          tw`cursor-pointer`
                        }
                      >
                        {infoText.normal} <strong>{infoText.strong}</strong>
                        {infoText.normal === 'Số tài khoản: ' ? (
                          <FaCopy tw="inline-block text-base ml-2 text-center text-savor-cake-primary-green-avocado-0" />
                        ) : null}
                      </TransferPara>
                    ))}
                    <TransferPara>
                      Nội dung chuyển khoản GHI CHÍNH XÁC là <br />
                    </TransferPara>
                    <TransferPara
                      tw="flex items-center justify-center gap-1 cursor-pointer"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          transferInfo?.description || transferContent
                        )
                        successToast('Đã copy nội dung chuyển khoản', {
                          autoClose: 500,
                          hideProgressBar: true,
                          pauseOnHover: false,
                        })
                      }}
                    >
                      <div tw="inline-block border-2 border-savor-cake-primary-green-avocado-0 rounded-md p-1 m-1">
                        {transferInfo?.description || transferContent}
                      </div>
                      <span>
                        <FaCopy tw="text-base text-savor-cake-primary-green-avocado-0" />
                      </span>
                    </TransferPara>
                    <div tw="text-center text-savor-cake-secondary-red-strawberry-0">
                      (* Nhấn vào mã nội dung để sao chép đúng nhất hoặc dùng mã
                      QR )
                    </div>
                  </div>
                </div>
              ) : null}
              {/* END Payment info */}

              {/* Lưu ý payment */}
              <AdjustedPara
                tw="flex flex-col items-center justify-center w-auto m-auto py-2"
                css={GreenBorderStyle}
              >
                <div tw="italic text-justify">
                  <div>
                    <strong>Mã QR đã có sẵn thông tin giao dịch</strong>
                  </div>
                  Nếu sử dụng điện thoại:
                  <li>Chụp ảnh màn hình lưu về máy</li>
                  <li>Mở chức năng Quét QR trong app ngân hàng</li>
                  <li>Chọn tải ảnh QR</li>
                </div>
              </AdjustedPara>
              <TransferPara
                tw="flex flex-col items-center justify-center w-auto m-auto py-2"
                css={GreenBorderStyle}
              >
                ⚠️ Nếu hủy đơn trước thời gian nhận hàng đã xác nhận:
                <div tw="text-justify">
                  <li>Đã cọc 50k - 100k: Mất toàn bộ tiền cọc</li>
                  <li>Đã cọc 100%: Hoàn lại 65% tổng giá trị đơn hàng</li>
                </div>
              </TransferPara>
            </GreenBorder>
          ) : null}
          {/* End Payment */}
          {/* Lưu ý liên hệ lại */}
          {!showQR ? (
            <GreenBorder>
              <AdjustedPara>
                Nhân viên cửa hàng sẽ liên hệ lại trong thời gian sớm nhất để
                hoàn tất đơn hàng.
              </AdjustedPara>
              <AdjustedPara>
                Hoặc để chủ động và thuận tiện hơn trong việc xác nhận đơn hàng
                của bạn
                <br />
                (nhất là các đợt cao điểm), <br />
                vui lòng nhắn lại mã đơn hàng <strong>
                  {onlineOrderId}
                </strong>{' '}
                cho chúng mình qua Zalo hoặc Messenger nha!
              </AdjustedPara>
              {/* <TransferPara>
                <ZaloLink />
              </TransferPara> */}
            </GreenBorder>
          ) : null}
          {/* Lưu ý Đồng kiểm */}
          <GreenBorder>
            <AdjustedPara>
              <strong>ĐỒNG KIỂM BÁNH VỚI SHIPPER</strong> để được xử lý luôn
              trong trường hợp bánh bị xô lệch/lỗi chất lượng.
            </AdjustedPara>
            <SmallPara>
              ✅ Nếu anh/ chị <strong>có đồng kiểm</strong> với shipper và phát
              hiện bánh bị xô lệch, mình vui lòng <u>không nhận bánh</u> và{' '}
              <u>chụp lại hình bánh lỗi</u> nhé ạ.
              <br />
              Shipper sẽ chuyển bánh lỗi quay về và Savor sẽ đổi lại bánh mới
              hoặc hoàn tiền lại cho mình.
            </SmallPara>
            <SmallPara>
              ❌ Nếu anh/ chị <strong>không đồng kiểm</strong> cùng shipper và
              phát hiện ra bánh bị xô lệch sau đó, Savor rất khó làm việc với
              bên đối tác giao hàng (do shipper đã chụp ảnh bánh không có lỗi từ
              chiều đi) và xin phép <u>từ chối xử lý các vấn đề xô lệch</u> ạ.
            </SmallPara>
            <AdjustedPara>
              Mình vui lòng thực hiện đầy đủ để được hỗ trợ tốt nhất nhé ạ.
            </AdjustedPara>
          </GreenBorder>
          {/* Lời cuối */}
          <AdjustedPara tw="mb-6">
            Mọi thắc mắc anh/ chị liên hệ hotline{' '}
            <strong>{HOTLINE_COMPLAINT}</strong>, hoặc nhắn Savor
            <strong> mã đơn hàng</strong> nha. <br />
            Savor xin cảm ơn ạ ❤️
          </AdjustedPara>
        </div>
        <PageLinks />
      </Layout>
    </TailwindLayout>
  )
}

const OrderSuccess = styled.div`
  background-image: url(${orderSuccessVector});
  background-position: center;
  background-size: cover;
  height: 20vw;
  width: 45vw;
  @media (min-width: 1024px) {
    height: 15vw;
    width: 35vw;
  }
`

const AdjustedPara = tw.p`text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl text-center px-4 sm:px-10`

const SmallPara = tw.p`my-3 lg:my-4 text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl text-left px-6 md:px-14 lg:px-16`

const TransferPara = tw(AdjustedPara)`px-4 mb-2`
